import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'

const StatistikPage = () => {
    return (
        <Layout pageTitle="Statistik">

            <p className="intro">
                Just nu är prio att samla in och kvalificera data för att visa den i kartan.
            </p>
            <p style={{textAlign: 'center'}}>
                Framöver kommer denna sida innehålla statistik om data i Laddify, exempelvis antal laddstationer, laddpunkter, uttagstyper, snittpris osv.<br/>
                Vad vill du se? Skriv till <Link to="/om/forslag">Laddify</Link> i sociala medier.
            </p>
            
        </Layout>
    )
}

export default StatistikPage